import React from 'react'
import About from '../about/About'


const Main = () => {
    


    return (
        <main>
            <About />
        </main>
    )

}

export default Main